/* allegro-credit.css */

html {
  height: 100%;
}

.back-to-top:hover,
.bg-custom,
.btn-custom,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.nav-pills > li.active > a {
  background-color: #12457a !important;
  color: #ffffff !important;
}

.navbar-custom {
  background-color: #ffffff !important;
}

.btn-white-fill,
.text-custom,
.btn-white-bordered:hover,
.btn-custom:hover,
.features-box i,
.pricing-column .plan-title {
  color: #12457a !important;
}

.btn-custom {
  border: 2px solid #12457a !important;
}

.btn-custom:focus {
  border: 2px solid #12457a !important;
  background-color: #ffffff !important;
  color: #12457a !important;
}

.btn-custom:hover {
  border: 2px solid rgba(255, 255, 255, 0.75) !important;
  background-color: #757575 !important;
  color: #ffffff !important;
}

.intro-form input:focus {
  border: 1px solid #12457a;
}

.experience {
  background-color: #f5f5f5;
}

.experience .title {
  color: #777777;
}

.experience .features-box {
  color: #12457a !important;
}

.experience .table-striped > tbody > tr:nth-of-type(even) {
  /*  background-color: #ffffff; */
}

/*
.experience h1, .experience .features-box i, .experience .text-muted, .experience h4, .experience p {
	color: #ffffff !important;
}
*/

.dropdown-menu {
  border-radius: 10px !important;
}

.navbar-custom .navbar-nav li ul li a:focus,
.navbar-custom .navbar-nav li ul li a:hover,
.navbar-custom .navbar-nav li ul li a:active {
  color: #ffffff;
}

.experience
  .carousel-inner
  > .item
  > .item-content
  blockquote
  > div.quote:before,
.experience
  .carousel-inner
  > .item
  > .item-content
  blockquote
  > div.quote:after {
  color: #ffffff;
}

.experience .carousel-indicators.dark > li {
  border-color: #737373;
}

.experience .carousel-indicators.dark > li.active {
  background-color: #737373;
}

#edit-application-form blockquote,
blockquote.quotes {
  border: 0;
  background-color: transparent;
  /*
  box-shadow: 0 5px 5px 0 rgba(200, 200, 200, 0.4);
  */
  position: relative;
  padding: 30px 90px;
}

#edit-application-form blockquote:before,
#edit-application-form blockquote:after,
blockquote.quotes:before,
blockquote.quotes:after {
  font-family: FontAwesome, "Font Awesome 5 Pro";
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 21px;
  color: #3c81ff;
}

#edit-application-form blockquote:before,
blockquote.quotes:before {
  content: "\f10d";
  top: 30px;
  left: 30px;
}

#edit-application-form blockquote:after,
blockquote.quotes:after {
  content: "\f10e";
  top: 30px;
  right: 30px;
}

#edit-application-form blockquote,
blockquote.quotes {
  padding-top: 0;
  padding-bottom: 0;
}

#edit-application-form blockquote:before,
#edit-application-form blockquote:after,
blockquote.quotes:before,
blockquote.quotes:after {
  font-size: 2em;
}

#edit-application-form blockquote:before,
blockquote.quotes:before {
  top: 0;
}

#edit-application-form blockquote:after,
blockquote.quotes:after {
  top: initial;
  bottom: 0;
}

.fill-height {
  height: 100%;
}

.row-flex > .col-wide.fill-height > .img-thumbnail.fill-height {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row-flex > .col-wide.fill-height > .img-thumbnail.fill-height > img {
  flex: 0;
}

/* apply */

#page-apply {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
}

#page-apply > header,
#page-apply > footer {
  flex: 0;
}

#page-apply > main {
  flex: 1;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

#page-apply .logo {
  min-height: 44px;
  height: 44px;
}

#page-apply .navbar > .container .navbar-merchant {
  margin-right: -15px;
}

#page-apply > main > pageresponse,
#page-apply > main > section:first-child {
  padding-top: 0;
}

#page-apply > main > pageresponse {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  margin: 11px;
}

#page-apply > main > pageresponse > .row > div > div.alert {
  border-left: 1px solid #ebccd1;
  border-right: 1px solid #ebccd1;
}

#page-apply
  > main
  > .hero-sm
  .carousel-inner.carousel-with-bg
  > .item
  > .item-content {
  height: auto;
}

#page-apply .hero-sm .hero-wrapper {
  padding: 15px;
}

#page-apply > footer {
  padding: 5px 0;
}

/* loading */
loading {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}

loading > span {
}

loading > span > i {
  font-size: 25vh !important;
  line-height: 1em !important;
  color: #ff0000;
}

loading > span.message {
  position: absolute;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.75);
  font-size: 30px;
}

.bootbox.modal-warning > .modal-dialog > .modal-content > .modal-header {
  margin-left: -25px;
  margin-top: -25px;
  margin-right: -25px;
  padding: 15px 25px;
  border-radius: 0;
}

.bootbox.modal-warning > .modal-dialog > .modal-content > .modal-footer {
  margin-left: -25px;
  margin-bottom: -25px;
  margin-right: -25px;
  padding: 15px 25px;
}

/************************* CSS RIBBONS *************************/

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
  z-index: 1000;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #555555;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #eeeeee;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
  text-shadow: none;
}
.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #eeeeee;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #eeeeee;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #eeeeee;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #eeeeee;
}

/* danger ribbon */
.ribbon.ribbon-danger span {
  background: #d9534f;
  color: #ffffff;
}

.ribbon.ribbon-danger span::before {
  border-left-color: #d9534f;
  border-top-color: #d9534f;
}

.ribbon.ribbon-danger span::after {
  border-right-color: #d9534f;
  border-bottom-color: #d9534f;
}
/* /danger ribbon */

/* warning ribbon */
.ribbon.ribbon-warning span {
  background: #f0ad4e;
  color: #ffffff;
}

.ribbon.ribbon-warning span::before {
  border-left-color: #f0ad4e;
  border-top-color: #f0ad4e;
}

.ribbon.ribbon-warning span::after {
  border-right-color: #f0ad4e;
  border-bottom-color: #f0ad4e;
}
/* /warning ribbon */

/* info ribbon */
.ribbon.ribbon-info span {
  background: #5bc0de;
  color: #ffffff;
}

.ribbon.ribbon-info span::before {
  border-left-color: #5bc0de;
  border-top-color: #5bc0de;
}

.ribbon.ribbon-info span::after {
  border-right-color: #5bc0de;
  border-bottom-color: #5bc0de;
}
/* /info ribbon */

/* success ribbon */
.ribbon.ribbon-success span {
  background: #5cb85c;
  color: #ffffff;
}

.ribbon.ribbon-success span::before {
  border-left-color: #5cb85c;
  border-top-color: #5cb85c;
}

.ribbon.ribbon-success span::after {
  border-right-color: #5cb85c;
  border-bottom-color: #5cb85c;
}
/* /success ribbon */

/* primary ribbon */
.ribbon.ribbon-primary span {
  background: #337ab7;
  color: #ffffff;
}

.ribbon.ribbon-primary span::before {
  border-left-color: #337ab7;
  border-top-color: #337ab7;
}

.ribbon.ribbon-primary span::after {
  border-right-color: #337ab7;
  border-bottom-color: #337ab7;
}
/* /primary ribbon */

.ribbon-fixed {
  position: fixed;
  top: 0;
  right: 0;
}

content > .ribbon-fixed {
  top: 25px;
  right: -5px;
}

content > .ribbon-fixed.ribbon-lg {
  top: 24px;
  right: -6px;
}

.ribbon.ribbon-lg {
  width: 150px;
  height: 150px;
  z-index: 1000;
}

.ribbon.ribbon-lg > span {
  font-size: 20px;
  line-height: 40px;
  width: 200px;
  top: 38px;
  right: -42px;
}

.ribbon.ribbon-fixed > span:before,
.ribbon.ribbon-fixed > span:after {
  border-width: 3px;
}

/* top left */
body > .ribbon.ribbon-tl {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  transform: rotate(-90deg);
}

body > .ribbon.ribbon-tl > span {
}

body > .ribbon.ribbon-tl > span::before,
body > .ribbon.ribbon-tl > span::after {
  display: none;
}
/* /top left */

/* bottom left */
body > .ribbon.ribbon-bl {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  transform: rotate(180deg);
}

body > .ribbon.ribbon-bl > span {
  transform: rotate(225deg);
}

body > .ribbon.ribbon-bl > span::before,
body > .ribbon.ribbon-bl > span::after {
  display: none;
}
/* /bottom left */

/************************* /CSS RIBBONS *************************/

/************************* /ADA COMPLIANCE RECOMMEDATIONS *************************/
.f-24 {
  font-size: 24px;
}

.f-18 {
  font-size: 18px;
  margin: 10px 0;
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  /*opacity: .5;*/
  transition: opacity 0.15s ease;
}
.carousel-control-prev {
  left: -85px;
}
.carousel-control-next {
  right: -106px;
}

.carousel-control-prev:focus {
  outline: 2px solid #12457a !important;
  background-color: #ffffff !important;
  color: #12457a !important;
  outline-offset: 1px;
}

.carousel-control-next:focus {
  outline: 2px solid #12457a !important;
  background-color: #ffffff !important;
  color: #12457a !important;
  outline-offset: 1px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(18, 69, 122)' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='rgb(18, 69, 122)' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators.dark > li {
  border-color: #737373;
}

.carousel-indicators.dark > li.active {
  border-color: #737373;
}

.text-muted {
  color: #707070;
}

.footer-alt .text-muted {
  color: #ababab;
}

.footer a {
  color: #ababab;
}

.footer #dns-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: unset;
  line-height: 22px;
  text-align: left;
  color: #ababab;
}

span.title {
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
}

.footer-alt .text-muted {
  color: #ababab;
}

span.title {
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.1;
}

.hero-wrapper h2,
.hero-wrapper p {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  width: 80%;
  margin: 30px auto 50px auto;
  color: #ffffff;
}

#faqs .nav > li > a:focus,
.nav > li > a:hover {
  background-color: #737373;
  color: #ffffff;
}

.panel-body a,
a.contact {
  text-decoration: underline;
  font-weight: bold;
  color: #2d6a9f !important;
}

@media (max-width: 320px) {
  .hero-wrapper {
    padding: 50px 0;
  }
  .hero-wrapper h4 {
    margin: 10px auto 20px auto;
  }
  h1 {
    font-size: 30px;
  }
  .hero-wrapper h1 {
    width: 90%;
    font-size: 30px;
    line-height: 36px;
  }
  .table-responsive th {
    font-size: 13px;
    padding: 8px 6px;
  }
  .table-responsive td {
    font-size: 12px;
    padding: 8px 6px;
  }
}

/************************* /ADA COMPLIANCE RECOMMEDATIONS *************************/

.footer .list-unstyled > li > a:focus,
.footer .list-unstyled > li > button:focus,
.footer .list-unstyled > li > button:hover,
a:hover {
  outline: 2px solid #12457a !important;
  background-color: #ffffff !important;
  color: #12457a !important;
  outline-offset: 1px;
}

.annotate {
  font-style: italic;
  color: #366ed4;
}

[role="table"] {
  display: table;
  background-color: transparent;
}

[role="table"] > div[id] {
  display: table-caption;
  text-align: center;
}

[role="table"] [role="row"] {
  display: table-row;
}

[role="table"] [role="cell"],
[role="table"] [role="columnheader"] {
  display: table-cell;
  padding: 8px 8px;
  width: 11em;
  white-space: nowrap;
  text-align: center;
}

[role="table"] [role="cell"][firstcell="true"],
[role="table"] [role="columnheader"][firstcolumnheader="true"] {
  display: table-cell;
  width: 800px;
  white-space: nowrap;
  text-align: left;
}

[role="table"] [role="columnheader"] {
  font-weight: bold;
  border-bottom: thin solid #888;
  width: 12em;
}
[role="table"] [role="columnheader"] [firstcell="true"] {
  font-weight: bold;
  border-bottom: thin solid #888;
  width: 100%;
}

[role="table"] [role="rowgroup"]:last-child [role="row"]:nth-child(odd) {
  background-color: #ddd;
}
[role="table"] [role="rowgroup"]:last-child [role="row"]:nth-child(even) {
  background-color: transparent;
}
